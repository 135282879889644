import Map from "./Map";
import ImageSlider from "./ImageSlider";
import PostSlider from "./PostSlider";
import Filter from "./Filter";
import Navigation from "./Navigation";
import Accordion from "./Accordion";

const filter = new Filter();
const navigation = new Navigation();
const map = new Map();
const imageSlider = new ImageSlider();
const postSlider = new PostSlider(map);

let accordionContainers = document.querySelectorAll(".accordion");
if (accordionContainers) {
	accordionContainers = Array.from(accordionContainers).map((accordion) => new Accordion(accordion));
}
