export default class Filter {
	constructor() {
		this.filterTrigger = document.getElementById("filter-trigger");
		this.filterCloseTrigger = document.getElementById("filter-close-trigger");
		this.filterWrapper = document.getElementById("filter-wrapper");

		if (this.filterTrigger) {
			this.filterTrigger.addEventListener("click", () => {
				this.filterWrapper.classList.remove("-right-[100vw]");
				this.filterWrapper.classList.add("right-0");
			});
		}

		if (this.filterCloseTrigger) {
			this.filterCloseTrigger.addEventListener("click", () => {
				this.filterWrapper.classList.remove("right-0");
				this.filterWrapper.classList.add("-right-[100vw]");
			});
		}
	}
}
