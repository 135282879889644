import Swiper, { Navigation, Pagination } from "swiper";

export default class ImageSlider {
	constructor() {
		this.slider = null;
		this.container = document.querySelector(".imageswiper");
		if (this.container) {
			this.initSlider();
		}
	}

	initSlider() {
		this.slider = new Swiper(this.container, {
			autoplay: false,
			speed: 800,
			spaceBetween: 0,
			slidesPerView: "auto",
			navigation: {},
			breakpoints: {
				320: {
					slidesPerView: this.container.dataset.mobile ? this.container.dataset.mobile : 1,
					spaceBetween: 16,
				},
				640: {
					slidesPerView: 2,
					spaceBetween: 24,
				},
				1200: {
					slidesPerView: this.container.dataset.desktop ? this.container.dataset.desktop : 1,
					spaceBetween: 24,
				},
			},
		});
	}
}
