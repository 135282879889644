export default class Navigation {
	constructor() {
		this.trigger = document.getElementById("navigation-trigger");
		this.close = document.getElementById("navgation-close-trigger");
		this.container = document.getElementById("navigation-wrapper");

		if (this.trigger) {
			this.trigger.addEventListener("click", () => {
				this.container.classList.remove("-right-[100vw]");
				this.container.classList.add("right-0");
			});
		}

		if (this.close) {
			this.close.addEventListener("click", () => {
				this.container.classList.remove("right-0");
				this.container.classList.add("-right-[100vw]");
			});
		}
	}
}
