import Swiper, { Navigation, Pagination } from "swiper";

export default class PostSlider {
	constructor(map) {
		this.breakpoint = window.matchMedia("(min-width:1140px)");
		this.mediaQueryList = window.matchMedia(this.breakpoint);
		console.log(this.breakpoint);
		this.map = map;
		this.slider = undefined;
		this.container = document.querySelector(".homeslider");
		this.mediaQueryList.addEventListener("change", this.breakpointChecker);
		this.breakpointChecker();
	}
	breakpointChecker() {
		// if larger viewport and multi-row layout needed
		if (this.breakpoint.matches === true) {
			// clean up old instances and inline styles when available
			if (this.slider !== undefined) this.slider.destroy(true, true);

			// or/and do nothing
			return;

			// else if a small viewport and single column layout needed
		} else if (this.breakpoint.matches === false) {
			// fire small viewport version of swiper
			return this.initSlider();
		}
	}

	initSlider() {
		this.slider = new Swiper(this.container, {
			autoplay: false,
			autoHeight: true,
			speed: 800,
			spaceBetween: 0,
			slidesPerView: "auto",
			navigation: {},
		});

		this.slider.on("slideChange", () => {
			const currentIndex = this.slider.activeIndex;
			const currentSlide = this.slider.slides[currentIndex];

			this.map.moveMapToId(currentSlide);
		});
	}
}
