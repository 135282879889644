import * as L from "leaflet";

export default class Map {
	constructor() {
		this.map = null;
		this.container = document.getElementById("map");
		this.coords = this.container.dataset.coords ? [this.container.dataset.coords.split(",")] : this.getCoords();
		this.initMap();
	}

	getCoords() {
		const array = Array.from(document.querySelectorAll(".angebot"));
		if (array) {
			return array.map((item) => {
				if (item.dataset.coords) {
					return {
						coords: item.dataset.coords.split(","),
						id: item.dataset.id,
						color: item.dataset.color,
						link: item.dataset.link,
					};
				}
			});
		}
	}

	initMap() {
		this.map = L.map(this.container, {
			center: this.container.dataset.coords ? this.container.dataset.coords.split(",") : [51.341699, 12.2535524],
			zoom: this.container.dataset.coords ? 16 : 10,
			zoomControl: false,
			scrollWheelZoom: window.innerWidth > 1024 ? true : false,
		});
		if (!this.container.dataset.coords && window.innerWidth > 1024) {
			this.map.on("zoomend", () => this.adjustMarker());
			this.map.on("moveend", () => this.adjustMarker());
		}

		this.addTileLayer();
	}

	addTileLayer() {
		L.tileLayer("https://{s}.basemaps.cartocdn.com/rastertiles/voyager_labels_under/{z}/{x}/{y}.png").addTo(
			this.map
		);

		this.addMarker();
	}

	addMarker() {
		if (this.coords) {
			this.coords.forEach((coord) => {
				if (coord.coords) {
					const icon = L.divIcon({
						className: "mapIcon",
						html: `
							<span class="mapIconInner" style="background-color: ${coord.color}"></span>
							<span class="mapIconOuter" style="background-color: ${coord.color}"></span>
						`,
					});
					const marker = L.marker(coord.coords).addTo(this.map);
					marker.id = coord.id;
					marker.addEventListener("click", () => {
						window.location = coord.link;
					});
				} else {
					L.marker(coord).addTo(this.map);
				}
			});
			const group = [];
			this.map.eachLayer((layer) => {
				if (layer instanceof L.Marker) {
					group.push(layer);
				}
			});
			const featureGroup = new L.featureGroup(group.slice(0, 16));
			this.map.fitBounds(featureGroup.getBounds());
		}
	}

	adjustMarker() {
		let IDs = [];
		this.map.eachLayer((layer) => {
			if (layer instanceof L.Marker) {
				if (this.map.getBounds().contains(layer.getLatLng())) {
					IDs = [...IDs, layer.id];
				}
			}
		});

		document.querySelectorAll(".angebot").forEach((item) => {
			item.parentElement.classList.add("hidden");
		});

		IDs.forEach((id) => {
			document.getElementById(id).classList.remove("hidden");
		});
	}

	moveMapToId(elem) {
		const newCenter = elem.querySelector(".angebot").dataset.coords.split(",");
		this.map.flyTo(newCenter, 14);
	}
}
