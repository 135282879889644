export default class Accordion {
	constructor(container) {
		this.accordion = container;
		this.trigger = this.accordion.querySelector(".accordion-trigger");
		this.body = this.accordion.querySelector(".accordion-body");
		this.icon = this.accordion.querySelector(".accordion-icon");

		this.trigger.addEventListener("click", () => this.toggleAccordion());
	}

	toggleAccordion() {
		this.body.classList.toggle("h-0");
	}
}
